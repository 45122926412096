@import '../../styles/imports';

.settings {
  @extend %extends-hide-scrollbar;
  width: 100vw;
  height: 100%;
  background-color: $colorBlack;
  overflow-y: scroll;

  .content {
    position: relative;
    padding: 12px 0 0;
    margin: 0 auto;
    width: 90%;
    max-width: 390px;
    // background-color: teal;

    // ------ Close button:

    > button.closeButton {
      @extend %extends-flex-xy-center;
      position: absolute;
      top: 0;
      // top: 30px;
      right: -10px;
      width: 40px;
      height: 40px;
      // background-color: green;

      > span {
        width: 20px;
        height: 20px;
        background-color: $colorRed;
        border-radius: 50%;
      }
    }

    // ------ Preview:

    > ul.previewActionList {
      padding: 0 0 40px;
      border-bottom: 1px solid $colorGrey;

      > li {
        display: flex;
        gap: 10px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        > span {
          &.title {
            min-width: 80px;
            color: $colorGrey;
          }
        }
      }
    }

    // ------ Controls:

    .controlsBlock {
      position: relative;
      padding: 15px 0 0;
      width: 100%;
      user-select: none;

      .selectBlock {
        display: flex;
        gap: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $colorGrey;

        .select {
          position: relative;
          width: 50%;
          margin-bottom: 15px;
          font-weight: 600;
          color: $colorWhite;
          background-color: #f2f2f2;

          > ul.selectMenu {
            position: absolute;
            left: 0;
            bottom: 50px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px 0;
            width: 100%;
            background-color: $colorGreyDark;

            > li {
              padding: 0 0 0 15px;
              text-transform: capitalize;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .selectButton {
            display: flex;
            align-items: center;
            padding: 0 0 0 15px;
            height: 40px;
            text-transform: capitalize;
            background-color: $colorGreyDark;

            &:hover {
              cursor: pointer;
            }

            // > span.title {
            //   @extend %extends-inline-ellipsis;
            // }

            &.open {
              > svg {
                transform: rotate(180deg);
              }
            }

            > svg path {
              fill: #000000;
            }
          }
        }
      }

      // ------ Prices:

      .pricesBlock {
        display: flex;
        gap: 15px;
        padding: 0 0 15px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid $colorGrey;
        // background-color: tomato;

        > ul.pricesList {
          display: block;
          width: 50%;
          // background-color: teal;

          > li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0 0 15px;
            height: 40px;
            color: $colorWhite;
            background-color: $colorGreyDark;

            &:not(:last-child) {
              margin-bottom: 15px;
            }

            // > span {} // *

            > button.deletePriceButton {
              @extend %extends-flex-xy-center;
              min-width: 40px;
              height: 40px;
              background-color: $colorRed;
            }
          }
        }

        .inputBlock {
          display: flex;
          align-items: flex-end;
          width: 50%;
          // background-color: red;

          > input {
            display: block;
            padding: 0 0 0 15px;
            height: 40px;
            width: 100%;
            font-size: 16px;
            outline: none;
            border: 0;
            color: $colorWhite;
            background-color: $colorGreyDark;
          }

          > button.addPriceButton {
            @extend %extends-flex-xy-center;
            min-width: 40px;
            height: 40px;
            background-color: $colorGreen;
          }
        }
      }

      > button.submitButton {
        width: 100%;
        height: 50px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        background-color: $colorBlue;
      }
    }
  }
}
