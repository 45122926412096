@import '../../styles/imports';

.formBlock {
  position: fixed;
  top: 0;
  padding: 40px 0 0;
  height: 100%;
  // background-color: tomato;
}

.tokenListBlock {
  padding: 0 0 41px;

  > ul.tokenList {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    // background-color: palevioletred;

    > li.tokenItem {
      // background-color: green;

      &:not(:last-child) {
        border-bottom: 1px solid $colorGreyDark;
      }

      > ul.itemRowList {
        display: flex;

        > li {
          display: flex;
          align-items: center;
          height: 40px;
          // background-color: red;

          &:not(:first-child) {
            justify-content: flex-end;
          }

          > span {
            font-size: 12px;
            // color: rgb(252, 252, 252);
            text-transform: uppercase;

            @include media-screen-orientation('min', $tablet) {
              font-size: 14px;
            }

            // &.symbol {}
            // &.price {}
          }

          &.symbol {
            min-width: 65px;
            font-weight: 600;
          }

          &.price {
            width: 100%;
            min-width: 85px;
            // background-color: teal;
          }

          &.action {
            width: 100%;
            min-width: 85px;
            // background-color: teal;
          }

          &.percent {
            width: 100%;
            min-width: 65px;
            // background-color: teal;
          }

          &.settings {
            width: 100%;

            > span {
              @extend %extends-flex-xy-center;
              width: 40px;
              height: 100%;
              // background-color: teal;

              > svg {
                width: 14px;
                height: 14px;
              }
            }
          }

          &:hover {
            &.settings {
              > span {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
