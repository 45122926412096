$mobile: 390px;
$tablet: 768px;
$desktop: 1024px;
$max: 1440px;

// ------ Color:

$colorWhite: white;
$colorGrey: #545454; // uni
$colorGreyMiddleDark: #2d2d2d;
$colorGreyDark: #1d1d1d;
$colorBlack: black;

// ---

$colorYellow: #ffdd00;
$colorBlue: #0057b7;
$colorGreen: #52e1af;
$colorRed: #da0037;

// ---

$mainColor: $colorBlack;

$ease0s: 0s ease;
$ease03s: 0.3s ease;
$ease03s03s: 0.3s ease 0.3s;
$ease03s01s: 0.3s ease 0.1s;

// ------ Global:

$shadowCard: rgba(0, 0, 0, 0.24) 0px 3px 8px;

$viewportHeight: 100svh;
