@import '../../styles/imports';

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: $colorBlack;
  border-top: 1px solid $colorGreyDark;
  z-index: 20;
}
