@import '../../styles/imports';

section.guard {
  position: fixed;
  min-width: 100%;
  height: 100svh;
  user-select: none;

  .content {
    height: 100%;

    .displayKey {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: transparent;
      pointer-events: none;

      > span.userKey {
        font-size: 60px;
        font-weight: 600;
        color: $colorGreen;
      }
    }

    > input {
      padding: 0;
      margin: 0;
      min-width: 100%;
      height: 100%;
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      color: transparent;
      caret-color: transparent;
      border: 0;
      outline: none;
      background-color: $colorBlack;
      cursor: none;
    }
  }
}
