@import '../../styles/imports';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: $colorBlack;
  // border-bottom: 1px solid $colorGreyDark;
  z-index: 20;

  .headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 100%;
  }
}
