@import '../../styles/imports';

main.dashboardBlock {
  @extend %extends-hide-scrollbar;
  position: fixed;
  width: 100%;
  height: $viewportHeight;
  overflow-y: scroll;
  z-index: 10;

  .dashboard {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    font-size: 14px;

    padding: 40px 15px 0;
    height: 60px;
    color: $colorGreen;
    background-color: $colorGreyDark;

    @include media-screen-orientation('min', $tablet) {
      height: 70px;
    }

    .battonsBlock {
      display: flex;
      align-items: center;

      > button {
        height: 40px;
        min-width: 40px;

        &.updateActionPrices {
          > svg {
            height: 30px;
          }
        }
      }
    }
  }
}

.errorBackdrop {
  @extend %extends-flex-xy-center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: $viewportHeight;

  > p {
    margin-bottom: 30px;
  }

  > button {
    display: block;
    border: none;
    outline: none;
    padding: 10px 30px;
    border-radius: 4px;
    cursor: pointer;
  }
}
