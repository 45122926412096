@import './vars';

%extends-flex-xy-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%extends-absolut-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%extends-before {
  content: '';
  display: block;
  margin-right: 10px;
  width: 6px;
  height: 6px;
}

%extends-text-hover-white {
  &:hover {
    color: $colorWhite;
    cursor: pointer;
  }
}

// ------ Transitions:

%extends-transition-03s {
  transition: $ease03s;
}

// ----------- overflow:

%extends-overflow-hidden {
  overflow: hidden;
}

%extends-overflow-y-scroll {
  overflow-y: scroll;
}

%extends-white-space-nowrap {
  white-space: nowrap;
}

%extends-hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}

// - line-clamp

%extends-webkit-box-vertical {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  @extend %extends-overflow-hidden;
}

%extends-line-clamp-1 {
  @extend %extends-webkit-box-vertical;
  -webkit-line-clamp: 1;
}

%extends-line-clamp-2 {
  @extend %extends-webkit-box-vertical;
  -webkit-line-clamp: 2;
}

%extends-line-clamp-3 {
  @extend %extends-webkit-box-vertical;
  -webkit-line-clamp: 3;
}

%extends-three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
