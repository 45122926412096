@import '../../styles/imports';

.loaderBlock {
  @extend %extends-flex-xy-center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $viewportHeight;

  // > svg {} // *
}
